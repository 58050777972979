import {
    Card, CardContent, CardActions, Button, TextField, Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
  } from "@mui/material";
import {useNavigate,useLocation,Link} from "react-router-dom"
import {useState, useEffect} from "react"
import Scout from "./Scout.js"
import Auth from '@aws-amplify/auth'
const playersRegex = /^([0-9]?[0-9]?,)*([0-9][0-9])$/

const PointSelect = ()=>{
  const location = useLocation()
  console.log("location2",location)
  let navigate = useNavigate()
  //const classes = useStyles()
  const [id,setId] = useState("")
  const [team, setTeam] = useState('')
  const [index, setIndex] = useState(1)
  const [players, setPlayers] = useState('')
  const [deltaScore, setDeltaScore] = useState(0)
  const [playGoal,setPlayGoal] = useState("NEUTRAL")

  useEffect(()=>{
    Auth.currentAuthenticatedUser().then((user)=>{
        setId(`${user.username}-nxlwc`)               
    })

},[])

  useEffect(()=>{

    const objStr =  window.localStorage.getItem("save")
    if (objStr){
      const obj = JSON.parse(objStr)
      //setId(obj.id)
      setTeam(obj.team)
      setIndex(obj.index)
      setPlayers(obj.players)
      setDeltaScore(obj.deltaScore)
      setPlayGoal(obj.playGoal?obj.playGoal:"NEUTRAL")
    }
},[])


useEffect(()=>{

  window.localStorage.setItem("save",JSON.stringify(
    {
      id:id,
      team:team,
      index:index,
      deltaScore: deltaScore,
      players: "",//players
      playGoal: playGoal
    }
  ))
},[id,team,index,players,deltaScore,playGoal])

  const invalidPlayer = !playersRegex.test(players) && (players!=='')
 console.log("player",players,invalidPlayer)
    return (
        <Card>
        <CardContent>
        <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '90%' },
      }}
      noValidate
      autoComplete="off">
        <div>
        <TextField
          required
          id="team-name"
          label="Team Name"
          value={team}
          onChange={e=>setTeam(e.target.value)}
        />

        {/*<TextField
          disabled={true}
          id="players"
          label="Player numbers, comma separated"
          helperText="Only available on Pro subscription. example: 02,04,22,50"
          value={players}
          onChange={e=>setPlayers(e.target.value)}
    />*/}
          <FormControl sx={{ m: 1, minWidth: 120 , width:"90%" }}>
                <InputLabel id="filter">Play Goal</InputLabel>
                <Select
                    labelId="playgoal-label"
                    id="playgoal-helper"
                    value={playGoal}
                    label="Select Play Goal"
                    onChange={e=>setPlayGoal(e.target.value)}
                    >
                        <MenuItem value={"NEUTRAL"}>Nothing special</MenuItem>
                        <MenuItem value={"ATTACK"}>Offensive play</MenuItem>
                        <MenuItem value={"BURN_CLOCK"}>Burn Clock</MenuItem>
                        <MenuItem value={"ALL_ASS"}>Run Down</MenuItem>
                        <MenuItem value={"COUNTER_ALL_ASS"}>Counter Run Down</MenuItem>

        </Select>
        <FormHelperText>Select the expected play based on score and time left</FormHelperText>
      </FormControl> 

        {/*<TextField
          
          id="delta-score"
          label="Score spread"
          type="number"
          helperText="example: +1 if team is leading by 1"
          value={deltaScore}
          onChange={e=>setDeltaScore(e.target.value)}
        />
        <TextField
          id="index"
          label="Point number"
          type="number"
          helperText="Will automatically increment after each point"
          value={index}
          onChange={e=>setIndex(e.target.value)}
    />*/}

        </div>
      
      </Box>


        </CardContent>
        <CardActions >
        <div style={{width: "100%"}}>
          <Box sx={{p:2,display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"row"}}>
          <Button variant="contained" 
          disabled = {invalidPlayer}
          onClick={()=>navigate("/",{state:{id:id, team:team,deltaScore:deltaScore, index:index, players: players.split(',')}})}
          >
            Home
          </Button>

          <Button variant="contained" 
          disabled = {invalidPlayer}
          onClick={()=>navigate("/scout",{state:{id:id, team:team,deltaScore:deltaScore, index:index, players: players.split(',')}})}
          >
            Go
          </Button>
          
          </Box>
          </div>
        </CardActions>
        </Card>
    )
}
  export default PointSelect;