import {useState, useEffect, useRef, useCallback} from "react"
import {
    Card,CardContent, CardActions,Grid, Button,Typography,Box
  } from "@mui/material";

import Webcam from "react-webcam";
import ReactPlayer from "react-player/lazy";
import {useNavigate} from "react-router-dom"
import {makeStyles} from "@mui/styles";
import Record from "@mui/icons-material/AdjustOutlined";  
import Recording from "@mui/icons-material/FiberManualRecordTwoTone";
import { Translate } from "@mui/icons-material";
import TouchEvent from "../TouchEvent.js"
import { useLocation } from "react-router-dom"
import {API,Storage} from "aws-amplify"
import {updatePoint} from "../graphql/mutations"


const useStyles = makeStyles(
    {
        root:{
            width: "100vw",
            height: "100vh"
        },
        webcam:{
            maxHeight: "100vh",
            height: "100%"
        },
        record:{
            transform: "Translate(-50%,-50%)",
            fontSize: "80px"
        },
        recording:{
            transform: "Translate(-50%,-50%)",
            fontSize: "80px",
            animation: `$recordBlink 2000ms infinite`,
        },
        "@keyframes recordBlink": {
            "0%": {opacity: 1},
            "50%": {opacity: .1},
            "100%": {opacity: 1}
          },
        debug:{
            position: "absolute" ,
            width: "10%",
              top: "50%" ,
              left: "80%"
        },
        playback:{
                width: "100%"
        },
        rotate90:{
            transform: "rotate(90deg)"
        },
        rotate270:{
            transform: "rotate(270deg)"
        },
        rotate180:{
            transform: "rotate(180deg)"
        },
        home:{

        },
        away:{

        }
    }
)

//const arrowTopLeft = <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g transform="rotate(-90 8 8)"><path fill="currentColor" fill-rule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g></svg>
const a = 5
const b = 30/14.833333333
const c = 18/14.833333333
const arrowTopLeft =(x,y,r,z)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(0,-4.1) rotate(-90 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
    }
const arrowTopRight = (x,y,r,z)=>{
return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1) rotate(0 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
}

const arrowBottomLeft =(x,y,r,z)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(0,-4.1) rotate(180 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
    }
const arrowBottomRight = (x,y,r,z)=>{
return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1) rotate(90 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
}



const dataHome=[["5",13.5,78],["4",40,72],["3",60,77.5],["2",80,72],
["1",102,78],["texas",60,42],["zeus",52,15],["god",60,15],["zeus2",68,15],["houston",44,32],
["delta",70,59], ["cobra",22,62],["s1",16,47],["s2",12,36],["s3",12,22],
["p1",40,15],["p2",30,15],["p3",83,15],["dallas",100,15],
["spur",78,32.5],["d1",102.5,57],["d2",88.5,52],
["d3",98.5,40],["d4",102.5,27.5]]

const Scout = (props)=>{
    const location = useLocation()
    console.log("location",location, props)
    const classes = useStyles()
    const [orientation, setOrientation] = useState(window.innerWidth < window.innerHeight?"portrait":"landscape")
    
    
    
    
    const [videoFilePath, setVideoFilePath] = useState(null);
    const [selector,setSelector] = useState(null) 
    const [selectedBunkers, setSelectedBunkers] = useState([])
    const [selectedBunker, setSelectedBunker] = useState(null)
    const [isHome,setIsHome]=useState(true)
    const [id,setId] = useState("0")
    const [team, setTeam] = useState('')
    const [index, setIndex] = useState(0)
    const [players, setPlayers] = useState(["+2","D"," "," ","X"])
    const [deltaScore, setDeltaScore] = useState(0)
    const [submiting,setSubmiting] = useState(false)
    const [playGoal,setPlayGoal] = useState("NEUTRAL")
    const [pointList,setPointList] = useState(null)
    const [editMode,setEditMode] = useState(false)
    const [activePlayIndex,setActivePlayIndex] = useState(0)
    const [videoUrls,setVideoUrls] = useState([])
    let navigate = useNavigate()

    useEffect(()=>{
        const objStr =  window.localStorage.getItem("pointList")
        if (objStr) setPointList(JSON.parse(objStr))
    },[])

    const imageRef= useRef()

    useEffect(()=>{
        if (imageRef && imageRef.current && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)){
            imageRef.current.addEventListener('touchstart',preventDefault,{passive:false})
            imageRef.current.addEventListener('touchmove',preventDefault,{passive:false})
            imageRef.current.addEventListener('touchend',preventDefault,{passive:false})

        }return function cleanup(){
            if (imageRef && imageRef.current && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)){
                imageRef.current.removeEventListener('touchstart',preventDefault,{passive:false})
                imageRef.current.removeEventListener('touchmove',preventDefault,{passive:false})
                imageRef.current.removeEventListener('touchend',preventDefault,{passive:false})
            }
              
        }

    },[imageRef,orientation])
    
    const preventDefault = e=>{
        e.preventDefault()}

    useEffect(()=>{
        const callAsync = async ()=>{
            try{
            console.log("RES",pointList.points[activePlayIndex])  
            const res=await Storage.get(pointList.points[activePlayIndex].s3URL,{expires: 86400})
                videoUrls[activePlayIndex]=res
                setVideoFilePath(videoUrls[activePlayIndex])
            }catch(error){
                console.log("STORAGE",error)
            }
            
            
        }

        if (pointList && pointList.points[activePlayIndex].s3URL){
            if (!videoUrls[activePlayIndex])
                callAsync()
            else
                setVideoFilePath(videoUrls[activePlayIndex])
        }//else setVideoFilePath(null)
    },[activePlayIndex,pointList])
    
    console.log("pointList",pointList)
    useEffect(()=>{
        const onOrientationChange = (event)=>{
           setOrientation(event.target.screen.orientation.angle===0 || event.target.screen.orientation.angle===180?"portrait":"landscape")
           
        }


        const onContextMenu = (event)=>{
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        const onResize = (event)=>{
                setTimeout(()=>{
                setOrientation(window.innerWidth < window.innerHeight?"portrait":"landscape")
                
                                },200)
        }
        


        if (window){
            window.addEventListener("orientationchange",onOrientationChange)
            window.addEventListener("contextmenu",onContextMenu)
            window.addEventListener("resize",onResize)
        }
            
        return function cleanup(){
            if (window){
                window.removeEventListener("orientationchange",onOrientationChange)
                window.removeEventListener("contextmenu",onContextMenu)
                window.removeEventListener("resize",onResize)
            }
        }
    },[])
    

    
    
    const onClick = (e)=>{
        e.preventDefault()
        console.log("event",e.clientX,e.clientY)
    }

    const onClickLeft = (e)=>{
        
        if (selectedB){
            if (selectedB.leftRight === -1) selectedB.leftRight = 0
            else selectedB.leftRight = -1
        }
        updateSelector()
        
    }
    const onClickRight = (e)=>{
        if (selectedB){
            if (selectedB.leftRight === 1) selectedB.leftRight = 0
            else selectedB.leftRight = 1
        }
        updateSelector()
        
    }
    const onClickPlus2 = (e)=>{
        if (selectedB){
            if (selectedB.player === "+2") selectedB.player = " "
            else selectedB.player = "+2"
        }
        updateSelector()
        
    }
    const onClickDive = (e)=>{
        if (selectedB){
            if (selectedB.player === "D") selectedB.player = " "
            else selectedB.player = "D"
        }
        updateSelector()
        
    }

    const updateSelector = ()=>{
    if (!selectedB)return
    if (selectedB.leftRight === -1)
    setSelector(<svg width="30%" height="100%" >{isHome?arrowTopLeft(4*rm,4*rm,2*rm):arrowBottomLeft(4*rm,4*rm,2*rm)}
    <text textAnchor="middle" alignmentBaseline="middle" 
        fontSize="9vw" 
        x={4*rm} y={isHome?4.1*rm:2.5*rm}>{selectedB.player}</text></svg>)
    else if (selectedB.leftRight===1)
    setSelector(<svg width="30%" height="100%" >{isHome?arrowTopRight(4*rm,4*rm,2*rm):arrowBottomRight(4*rm,4*rm,2*rm)}
    <text textAnchor="middle" alignmentBaseline="middle" 
        fontSize="9vw" 
        x={4*rm} y={isHome?4.1*rm:2.5*rm}>{selectedB.player}</text></svg>)
    else setSelector(
        <svg width="30%" height="100%" ><circle stroke="#000" fill="transparent"
        strokeWidth="4.2" cx={4*rm} cy={isHome?4*rm-1:2.5*rm-1} r={2*rm-2}/>
        <text textAnchor="middle" alignmentBaseline="middle" 
        fontSize="9vw" 
        x={4*rm} y={isHome?4.1*rm:2.5*rm}>{selectedB.player}</text>
        </svg>)
    }



    useEffect(()=>{
        const onSubmit2 = async ()=>{
            let point = {
                id: pointList.points[activePlayIndex].id,
                team: pointList.points[activePlayIndex].team,
                index: pointList.points[activePlayIndex].index,
                isHome: isHome,
                
                breakout:selectedBunkers.map(b=>{return {
                    direction: b.leftRight===0?"UNKNOWN":b.leftRight===-1?"LEFT":"RIGHT",
                    bunkerIndex: b.index,
                    Player: b.player!=='+2'&&b.player!=='D'?b.player:"",
                    double: b.player==='+2',
                    dive: b.player==='D'
                }
                }),
                timestamp: new Date().toISOString(),
            
            }
            try{
                const res = await API.graphql({
                query: updatePoint,
                variables:{
                    input: point
                },
                authMode: "AWS_IAM"
            })
            }catch(e){
                alert("failed to store in db" +JSON.stringify(point))
                alert("failed to store in db"+ JSON.stringify(e))
            }


            navigate("/analytics",{state:{team:pointList.team, analyzeBy:pointList.analyzeBy}})
        }
        if (submiting) onSubmit2()
    },
    [submiting])


    const px = window.screen.width/120
    const rm = window.screen.width/30

    const onSwipe = async e=>{
        console.log("onSwipe",e)
        if (e === "bottom" && isHome)
            setIsHome(false)
        else if (e=== "top" && !isHome)
            setIsHome(true)
    }

    const onTouchBegin = async e=>{
        if (!editMode)return
        console.log("onTouchBegin",e)
        setSelector(
            <svg width="30%" height="100%" >
            <circle stroke="#000" fill="transparent"
            strokeWidth="4.2" cx={4*rm} cy={isHome?4*rm-1:2.5*rm-1} r={2*rm-2}/></svg>)
    }
    const onTouchLeftRight = async (e,f)=>{
        if (!editMode)return
        console.log("onTouchLeftRight",e,f)
        if (e===-1)
        setSelector(<svg width="30%" height="100%" >{isHome?arrowTopLeft(4*rm,4*rm,2*rm):arrowBottomLeft(4*rm,4*rm,2*rm)}
        <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="9vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{players[bounded(f+Math.floor(players.length/2)-1)]}</text></svg>)
        else if (e===1)
        setSelector(<svg width="30%" height="100%" >{isHome?arrowTopRight(4*rm,4*rm,2*rm):arrowBottomRight(4*rm,4*rm,2*rm)}
        <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="9vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{players[bounded(f+Math.floor(players.length/2)-1)]}</text></svg>)
        else setSelector(
            <svg width="30%" height="100%" ><circle stroke="#000" fill="transparent"
            strokeWidth="4.2" cx={4*rm} cy={isHome?4*rm-1:2.5*rm-1} r={2*rm-2}/>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="9vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{players[bounded(f+Math.floor(players.length/2)-1)]}</text>
            </svg>)
    }
    const  bounded=(x)=>{
        if (x<0)return 0
        else if (x>=players.length) return players.length-1
        else return x
    } 

    const onTouchPlayer = async e=>{
        console.log("onTouchPlayer",e)
    }
    const onTouchEnd = async (leftRight,updown,children)=>{
        if (!editMode)return
        console.log("onTouchEnd",children.key,leftRight,updown)
        if (leftRight!==2){
            const found = selectedBunkers.findIndex(p=>p.index===parseInt(children.key))
            if (found>=0)selectedBunkers.splice(found,1)
            if (players[bounded(updown+Math.floor(players.length/2)-1)]==="X"){
                
                setSelectedBunkers([...selectedBunkers])
                setSelector(null)
                setSelectedBunker(null)

            }else{
                if (selectedBunkersCount()<5){
                    setSelectedBunker(parseInt(children.key))
                    setSelectedBunkers([...selectedBunkers,{index:parseInt(children.key),leftRight:leftRight,player:players[bounded(updown+Math.floor(players.length/2)-1)]}])
                }else setSelector(null)
            }
        }else
            setSelector(null)
    }

    const selectedBunkersCount = ()=>{
        let res = 0
        selectedBunkers.forEach(b=>{
            if (b.player==="+2")res = res + 2 
            else res++
        })
        return res
    }

    useEffect(()=>{if (pointList)loadPoint(0)},[pointList])

    const loadPoint = (i)=>{
        setSelectedBunkers(
            pointList.points[i].breakout.map(b=>{return {
                leftRight: b.direction==="UNKNOWN"?0:b.direction==="LEFT"?-1:1,
                index: b.bunkerIndex,
                player: b.double?"+2":b.dive?"D":""
            }})
        )
        setActivePlayIndex(i)
    }
    let selectedB = selectedBunkers.find(f=>f.index === selectedBunker)

    console.log("selectedBunkers",selectedBunkers)

    return (
        orientation==="portrait"?
        <>
        {videoFilePath ? 
                                <ReactPlayer
                                  controls
                                  playsinline={true}
                                  muted={true}
                                  loop={true}
                                  playing={true}
                                  className={classes.playback}
                                  width="100%"
                                  height="100%"
                                  style={{maxHeight: "328px"}}
                                  url={videoFilePath}
                                />   :<div width="100%"
                                           height="328px" 
                                           style={{maxHeight: "328px"}}>
                                <></>
                                <Typography variant="body2" 
                                            textAlign="center" 
                                            color="#777"
                                            padding= "10px">
            No video recorded for this point 
                                </Typography>

                                </div> 
        }
        <div style={{marginLeft: "10px", textAlign:"center", width:"100%", height:`${7.6*rm}px`}}>
        {selector && <><svg onClick={onClickLeft} width="17.5%" height="100%" >{isHome?arrowTopLeft(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===-1?"#000":"#AAA"):arrowBottomLeft(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===-1?"#000":"#AAA")}
        </svg>
        <svg onClick={onClickRight} width="17.5%" height="100%" >{isHome?arrowTopRight(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===1?"#000":"#AAA"):arrowBottomRight(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===1?"#000":"#AAA")}
        </svg>
        {selector}
        <svg onClick={onClickPlus2} width="17.5%" height="100%" ><circle stroke={selectedB && selectedB.player==="+2"?"#000":"#AAA"} fill="transparent"
            strokeWidth="2.1" cx={2*rm} cy={3*rm} r={1*rm-1}/>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="4.5vw" fill={selectedB && selectedB.player==="+2"?"#000":"#AAA"}
            x={2*rm} y={3.06*rm}>+2</text>
            </svg>
        <svg onClick={onClickDive} width="17.5%" height="100%" ><circle stroke={selectedB && selectedB.player==="D"?"#000":"#AAA"} fill="transparent"
            strokeWidth="2.1" cx={2*rm} cy={3*rm} r={1*rm-1}/>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="4.5vw" fill={selectedB && selectedB.player==="D"?"#000":"#AAA"}
            x={2*rm} y={3.06*rm}>D</text>
            </svg>
        </>
        }
        </div>
        <TouchEvent onSwipe={onSwipe}>
        <div ref={imageRef} style={{touchAction:"none", position:"relative" ,overflow: "hidden", width:"100%", height:6*window.screen.width/8}}>
        
        <svg width="100%" height="100%" style={{position: "absolute",top:0,left:0, zIndex:10}}>


                {selectedBunkers.map(b=>
                    {if(b.leftRight===0){
                        return <><circle stroke="#000" fill="transparent"
                            strokeWidth="2.4" cx={dataHome[b.index][1]*px} cy={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px} r={rm}/>
                            <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.player}</text>
                            </>
                    }else if (b.leftRight===-1){
                        return <>{isHome?arrowTopLeft(dataHome[b.index][1]*px,dataHome[b.index][2]*px,rm):
                            arrowBottomLeft(dataHome[b.index][1]*px,(93-dataHome[b.index][2])*px,rm)}
                        <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.player}</text></>
                    }else {
                        return <>{isHome?arrowTopRight(dataHome[b.index][1]*px,dataHome[b.index][2]*px,rm):
                            arrowBottomRight(dataHome[b.index][1]*px,(93-dataHome[b.index][2])*px,rm)}
                        <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.player}</text></>
                    }
                })}
                {selectedBunker !==null &&
                    <circle stroke="#333" fill="transparent"
                            strokeWidth="1" strokeDasharray="5,5" cx={dataHome[selectedBunker][1]*px} cy={isHome?dataHome[selectedBunker][2]*px:(90-dataHome[selectedBunker][2])*px} r={1.5*rm}/>
                }
                {dataHome.map((v,i)=>
                    <TouchEvent k={i} onTouchBegin={onTouchBegin} 
                    onTouchLeftRight={onTouchLeftRight}
                    onTouchPlayer={onTouchPlayer}
                    onTouchEnd={onTouchEnd}>
                <circle key={i} id={`Home${v[0]}`} stroke="#aaa" fill="transparent"
                strokeWidth="0" strokeDasharray="5,5" onClick={onClick} cx={v[1]*px} cy={isHome?v[2]*px:(90-v[2])*px} r={1.5*rm}/>
                </TouchEvent>)}

        </svg>
        <img src="nxlwc.jpg" width={window.screen.width} style={{marginTop:isHome?-4*window.screen.width/8:0 , opacity:.5, transitionProperty: "margin-top", transitionDuration:"300ms", transitionDelay:"50ms"}} />
        </div>
        </TouchEvent>
        {editMode?<div style={{width: "100%"}}>
            <Box sx={{p:2,display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"row"}}>
                <Button variant="contained" 
                onClick={()=>setEditMode(false)}
                >
                  Cancel
                </Button>
                
                <Button variant="contained" 
                disabled = {selectedBunkersCount()<4 || submiting }
                onClick={()=>setSubmiting(true)}
                >
                  Submit
                </Button>
                
                </Box>
        </div>:
        <div style={{width: "100%"}}>
             <Box sx={{p:2,display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"row"}}>
             <Button variant="contained" 
                onClick={()=>navigate("/analytics",{state:{team:pointList.team, analyzeBy:pointList.analyzeBy}})}
                >
                  Back
                </Button>
                <Button variant="contained" 
                onClick={()=>setEditMode(true)}
                >
                  Edit
                </Button>
                <Button variant="contained" 
                disabled={activePlayIndex===0}
                onClick={()=>loadPoint(activePlayIndex-1)}
                >
                  Prev
                </Button>
                <span>{activePlayIndex+1}</span>
                <Button variant="contained" 
                disabled={!pointList || activePlayIndex===(pointList.points.length-1)}
                onClick={()=>loadPoint(activePlayIndex+1)}
                >
                  Next
                </Button>
                
                </Box>
        </div>}
        
        </>:
      
            <div className={classes.webcam} style={{ width:"100%", height:"100%"}}>
            {videoFilePath ? 
                                <ReactPlayer
                                  controls
                                  playsinline={true}
                                  muted={true}
                                  loop={true}
                                  playing={true}
                                  className={classes.playback}
                                  width="100%"
                                  height="100%"
                                  style={{maxHeight: "328px"}}
                                  url={videoFilePath}
                                />   :<div width="100%"
                                           height="100%" 
                                           style={{maxHeight: "328px"}}>
                                <></>
                                <Typography variant="body2" 
                                            textAlign="center" 
                                            color="#777"
                                            padding= "10px">
            No video recorded for this point 
                                </Typography>

                                </div> 
        }
            </div>


    )
}
  export default Scout;
