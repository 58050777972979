/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      teamName
      division
      features
      tournaments {
        items {
          id
          accountId
          tournamentLeague
          tournamentDivision
          tournamentDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $id: ID
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        teamName
        division
        features
        tournaments {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTournamentForAccount = /* GraphQL */ `
  query GetTournamentForAccount($id: ID!) {
    getTournamentForAccount(id: $id) {
      id
      accountId
      tournamentLeague
      tournamentDivision
      tournamentDate
      points {
        items {
          id
          team
          index
          isHome
          s3URL
          timestamp
          notes
          pathway
          deltaScore
          playGoal
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tournament {
        league
        date
        layout
        layoutImg
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTournamentForAccounts = /* GraphQL */ `
  query ListTournamentForAccounts(
    $id: ID
    $filter: ModelTournamentForAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTournamentForAccounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        tournamentLeague
        tournamentDivision
        tournamentDate
        points {
          nextToken
        }
        tournament {
          league
          date
          layout
          layoutImg
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTournament = /* GraphQL */ `
  query GetTournament($league: String!, $date: AWSDate!) {
    getTournament(league: $league, date: $date) {
      league
      date
      layout
      layoutImg
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTournaments = /* GraphQL */ `
  query ListTournaments(
    $league: String
    $date: ModelStringKeyConditionInput
    $filter: ModelTournamentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTournaments(
      league: $league
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        league
        date
        layout
        layoutImg
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPoint = /* GraphQL */ `
  query GetPoint($id: ID!, $team: String!, $index: Int!) {
    getPoint(id: $id, team: $team, index: $index) {
      id
      team
      index
      isHome
      s3URL
      breakout {
        direction
        bunkerIndex
        dive
        double
        Player
      }
      timestamp
      notes
      pathway
      deltaScore
      playGoal
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPoints = /* GraphQL */ `
  query ListPoints(
    $id: ID
    $teamIndex: ModelPointPrimaryCompositeKeyConditionInput
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPoints(
      id: $id
      teamIndex: $teamIndex
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        team
        index
        isHome
        s3URL
        breakout {
          direction
          bunkerIndex
          dive
          double
          Player
        }
        timestamp
        notes
        pathway
        deltaScore
        playGoal
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
