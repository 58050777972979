import {
    Card, CardContent, CardActions
  } from "@mui/material";
import {Link} from "react-router-dom"
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(
  {
      root:{
          width: "100vw",
          height: "100vh"
      }
  })
  
const NewLayout = ()=>{

const onClick =()=>{
  window.parent.postMessage('cancel','*')
}
const onClick2 =()=>{
  const obj = [{
    productId:"prod1",
    productName:"prod1 name",
    productDescription:"prod1 description",
    attributes:[
    {type:"String",name:"myString",value:"asd"},
    {type:"Float",name:"myFloat",value:1.1},
    {type:"Int",name:"myInt",value:1},
    {type:"String Array",name:"myStringArray",value:["123","asd","wer"]},
    {type:"Float Array",name:"myFloatArray",value:[1.1,1.2,1.3]},
    {type:"Int Array",name:"myIntArray",value:[1,2,3]},
    ]
    },
    {
    productId:"prod2",
    productName:"prod2 name",
    productDescription:"prod2 description",
    attributes:[
    {type:"String",name:"bla",value:"asd"},
    {type:"Float",name:"bli",value:1.1},
    {type:"Int",name:"blu",value:1},
    {type:"String Array",name:"sadsad",value:["123s","asd","wer"]},
    ]
    }
    ]
  window.parent.postMessage(obj,'*')
}

    return (
        <Card>
        <CardContent onClick={onClick}>new layout contents</CardContent>
        <CardActions onClick={onClick2}>new layout action</CardActions>
        </Card>
    )
}
  export default NewLayout;