import { Button } from "@mui/material";
import {Outlet, Link} from "react-router-dom"
import React, { useEffect, useState } from "react";
import {
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
//import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { I18n, Auth } from "aws-amplify";

const services = {
  async handleSignUp(formData) {
    let { username, password, attributes } = formData;
    // custom username
    username = username.toLowerCase();
    attributes.email = attributes.email.toLowerCase();

    return Auth.signUp({
      username,
      password,
      attributes,
    });
  },
};

const Root = ()=>{
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const { route } = useAuthenticator((context) => [context.route])

    return (
         
        <Authenticator>{({signOut, user})=>(
        <><div>Free beta for NXL World Cup 2022.</div>
        <div> Would love your feedback on the facebook page, especially the bugs or missing features.</div>
        <div> 
          Do not use "Scout" on PC, only supported on Iphones and Androids as it requires a back camera. May work on tablets, but not tested.
          
        </div>
        <div><a href="https://www.facebook.com/scoutingpb/videos/429524872512281" target="_blank">Demo Video</a></div>
        <div><a href="https://www.facebook.com/scoutingpb" target="_blank">Scoutingpb facebook page</a></div>
        
        <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem"
        }}
      >
        {/*<Link to="/navigation">Navigation</Link> |{" "}
        <Link to="/newlayout">NewLayout</Link> |{" "}*/}
        <Link to="/accountinfo">Account Info</Link> |{" "}
        {/*<Link to="/tournamentselect">Select Tournament</Link> |{" "}*/}
        <Link to="/pointselect">Scout</Link> |{" "}
        <Link to="/analytics">Analytics</Link> |{" "}
        {/*<Link to="/scout">Scout</Link>*/}

      </nav>
      <button onClick={signOut}>Sign out</button>
        
            <Outlet /></>)}
        </Authenticator>
    )
}
  export default Root;