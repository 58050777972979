import {
    Card, CardContent, CardActions,Button
  } from "@mui/material";
import {makeStyles} from "@mui/styles";  

import {useNavigate,useLocation,Link} from "react-router-dom"
import {useState, useEffect, useRef, useCallback} from "react"

const useStyles = makeStyles(
    {
        root:{
            width: "100vw",
            height: "100vh"
        }
    }
)
const Navigation = ()=>{
    const classes = useStyles()
    const location = useLocation()
    let navigate = useNavigate()
    const [orientation, setOrientation] = useState(window.innerWidth < window.innerHeight?"portrait":"landscape")
    const [test, setTest] = useState("z")
    const [id,setId] = useState()
    const [team, setTeam] = useState('')
    const [index, setIndex] = useState(0)
    const [players, setPlayers] = useState('')
    const [deltaScore, setDeltaScore] = useState(0)
    useEffect(()=>{
        const onOrientationChange = (event)=>{
           setOrientation(event.target.screen.orientation.angle===0 || event.target.screen.orientation.angle===180?"portrait":"landscape")
           setTest(test+"a")
        }
        const onIphoneOrientationChange = ()=>{
            setOrientation(window.orientation===0 || window.orientation===180?"portrait":"landscape")
            setTest(test+"b")
        }

        const onContextMenu = (event)=>{
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        const onResize = (event)=>{
            var userAgent = window.navigator.userAgent;
            //if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                // iPad or iPhone
                setTimeout(()=>{setOrientation(window.innerWidth < window.innerHeight?"portrait":"landscape")
                setTest(test+"b")},200)
             //}
        }
        


        if (window){
            window.addEventListener("orientationchange",onOrientationChange)
            window.addEventListener("contextmenu",onContextMenu)
           
            window.addEventListener("resize",onResize)
        }
            
        return function cleanup(){
            if (window){
               window.removeEventListener("orientationchange",onOrientationChange)
                window.removeEventListener("contextmenu",onContextMenu)
                
                window.removeEventListener("resize",onResize)
            }
        }
    },[])
    return (
        <Card className={classes.root}>
        <CardContent>navigation contents</CardContent>
        <CardActions>{orientation}-{window.orientation}-{test}
        <Button variant="contained" 
          onClick={()=>navigate("/scout",{state:{id:id, team:team,deltaScore:deltaScore, index:index, players: players.split(',')}})}
          >
            Go
          </Button>
        
        </CardActions>
        </Card>
    )
}
  export default Navigation;