import {useMemo, useState, useEffect, useRef, useCallback} from "react"
import {
    Card,CardContent, CardActions,Grid, Button,Typography,Box,
    Radio,
    RadioGroup,
    Tooltip,
    FormControl,
    Checkbox,
    FormControlLabel,
    TextField,
    InputLabel,
    Select,
    Divider,
    MenuItem,
    FormHelperText,
  } from "@mui/material";

import ReactPlayer from "react-player/lazy";
import {useNavigate} from "react-router-dom"
import {makeStyles} from "@mui/styles";
import Record from "@mui/icons-material/AdjustOutlined";  
import Recording from "@mui/icons-material/FiberManualRecordTwoTone";
import { Translate } from "@mui/icons-material";
import TouchEvent from "../TouchEvent.js"
import { useLocation } from "react-router-dom"
import {API,Storage} from "aws-amplify"
import {createPoint} from "../graphql/mutations"
import {listPoints} from "../graphql/queries"
import Auth from '@aws-amplify/auth'
import DropdownTreeSelect from "react-dropdown-tree-select";

const useStyles = makeStyles(
    {
        root:{
            width: "100vw",
            height: "100vh"
        },
        webcam:{
            maxHeight: "100vh",
            height: "100%"
        },
        record:{
            transform: "Translate(-50%,-50%)",
            fontSize: "80px"
        },
        recording:{
            transform: "Translate(-50%,-50%)",
            fontSize: "80px",
            animation: `$recordBlink 2000ms infinite`,
        },
        "@keyframes recordBlink": {
            "0%": {opacity: 1},
            "50%": {opacity: .1},
            "100%": {opacity: 1}
          },
        debug:{
            position: "absolute" ,
            width: "10%",
              top: "50%" ,
              left: "80%"
        },
        playback:{
                width: "100%"
        },
        rotate90:{
            transform: "rotate(90deg)"
        },
        rotate270:{
            transform: "rotate(270deg)"
        },
        rotate180:{
            transform: "rotate(180deg)"
        },
        home:{

        },
        away:{

        }
    }
)

//const arrowTopLeft = <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g transform="rotate(-90 8 8)"><path fill="currentColor" fill-rule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g></svg>
const a = 5
const b = 30/14.833333333
const c = 18/14.833333333
const arrowTopLeft =(x,y,r)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(0,-4.1) rotate(-90 8 8)`}><path fill="currentColor" fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
    }
const arrowTopRight = (x,y,r)=>{
return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1) rotate(0 8 8)`}><path fill="currentColor" fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
}

const arrowBottomLeft =(x,y,r)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(0,-4.1) rotate(180 8 8)`}><path fill="currentColor" fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
    }
const arrowBottomRight = (x,y,r)=>{
return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1) rotate(90 8 8)`}><path fill="currentColor" fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
}

const dive=(x,y,r)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1)`}><path fill="currentColor" fillRule="evenodd" d="M1852.2,4996.6c-113-42.1-206.8-128.3-266.1-243.2c-47.9-90-53.6-120.6-47.9-237.4l5.7-134l754.4-1415c494-926.7,775.5-1434.1,817.6-1472.4c32.5-32.6,601.2-350.4,1261.8-704.6l1202.5-647.2l568.7-963.1c312.1-530.4,597.4-991.8,631.9-1028.2c137.9-139.8,360-178.1,545.7-90c224,103.4,338.9,382.9,248.9,601.2c-17.2,38.3-381,664.4-809.9,1390.1c-561,947.8-796.5,1332.7-842.5,1367.1c-40.2,30.6-450,185.7-1089.5,413.6l-1024.4,365.7l-698.9,1311.6c-382.9,721.9-716.1,1332.7-739.1,1355.6C2248.6,4998.5,2015,5056,1852.2,4996.6z"/><path d="M7188.6,1619c-201.1-70.9-354.2-214.5-453.8-423.2c-53.6-114.9-59.4-143.6-59.4-310.2c0-172.3,3.8-189.6,72.8-327.4c82.3-170.4,214.5-300.6,377.2-377.2c93.8-44.1,128.3-49.8,315.9-49.8c191.5,0,220.2,3.8,319.8,53.6c233.6,114.9,392.5,321.7,438.5,574.4c61.3,335.1-118.7,675.9-438.5,829.1C7615.6,1659.2,7341.8,1672.6,7188.6,1619z"/></g>
}
const dataHome=[["5",13.5,78],["4",40,72],["3",60,77.5],["2",80,72],
["1",102,78],["texas",60,42],["zeus",52,15],["god",60,15],["zeus2",68,15],["houston",44,32],
["delta",70,59], ["cobra",22,62],["s1",16,47],["s2",12,36],["s3",12,22],
["p1",40,15],["p2",30,15],["p3",83,15],["dallas",100,15],
["spur",78,32.5],["d1",102.5,57],["d2",88.5,52],
["d3",98.5,40],["d4",102.5,27.5]]

const Analytics = (props)=>{
    const location = useLocation()
    console.log("location",location, props)
    const classes = useStyles()
    const [orientation, setOrientation] = useState(window.innerWidth < window.innerHeight?"portrait":"landscape")
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [videoFilePath, setVideoFilePath] = useState(null);
    const [selector,setSelector] = useState(null) 
    const [selectedBunkers, setSelectedBunkers] = useState([])
    const [isHome,setIsHome]=useState(true)
    const [stats, setStats] = useState(new Array(dataHome.length).fill(0))
    const [id,setId] = useState()
    const [team, setTeam] = useState(location.state?location.state.team:"")
//    const [team, setTeam] = useState("")
    const [teams,setTeams] = useState(new Map())
    const [analyzeBy,setAnalyzeBy] = useState(location.state?location.state.analyzeBy:"All Points")
    const [playsMap,setPlaysMap] = useState(new Map())
    const [nb,setNb] = useState({
        nbHome:0,
        nbAway:0,
        nbNeutral:0,
        nbAttack:0,
        nbBurnClock:0,
        nbAllAss:0,
        nbCounterAllAss:0
    })


    let navigate = useNavigate()
    useEffect(()=>{
        Auth.currentAuthenticatedUser().then((user)=>{
            setId(user.username)     
        })
    
    },[])

    const imageRef= useRef()

    useEffect(()=>{
        if (imageRef && imageRef.current && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)){
            imageRef.current.addEventListener('touchstart',preventDefault,{passive:false})
            imageRef.current.addEventListener('touchmove',preventDefault,{passive:false})
            imageRef.current.addEventListener('touchend',preventDefault,{passive:false})

        }return function cleanup(){
            if (imageRef && imageRef.current && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)){
                imageRef.current.removeEventListener('touchstart',preventDefault,{passive:false})
                imageRef.current.removeEventListener('touchmove',preventDefault,{passive:false})
                imageRef.current.removeEventListener('touchend',preventDefault,{passive:false})
            }
              
        }

    },[imageRef,orientation])
    
    const preventDefault = e=>{
        e.preventDefault()}

    useEffect(()=>{
        async function fetchPoints(){
        if (id){
            try{
                console.log("input:",`${id}-nxlwc`)
                const res = await API.graphql({
                query: listPoints,
                variables:{
                    id: `${id}-nxlwc`
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            console.log("fectched", res)
            if (res.data.listPoints){
               const pointMap = new Map()
                res.data.listPoints.items.forEach(p=>{
                    if (pointMap.has(p.team)){
                        pointMap.get(p.team).push(p)
                    } else {
                        pointMap.set(p.team,[p])
                    } 
                }
                )
               setTeams(pointMap)
            }
    
            }catch(e){
                alert("failed to fetch points from  db", e.message)
            }
        }
        }   
        fetchPoints()
    },[id])

    
    const handleChangeTeam = (e)=>{
        setTeam(e.target.value)
    }

    const onClick = (e)=>{
        e.preventDefault()
        console.log("event",e.clientX,e.clientY)
    }


    const px = window.screen.width/120
    const rm = window.screen.width/30


    const onSwipe = async e=>{
        console.log("onSwipe",e)
        if (e === "bottom" && isHome)
            setIsHome(false)
        else if (e=== "top" && !isHome)
            setIsHome(true)
    }

    const valueAt = (key)=>{
        
        const f = playedBunkers.find(b=>b && b.index===parseInt(key))
        console.log("onTouchBegin",f)
        if (f) return `${f.count}/${totalPointForFilter()}`
        else return
    }

    const onTouchBegin = async e=>{
        
        const val = playedBunkers.find(b=>b && b.index===parseInt(e.key))
        
        if (val) setSelector(
            <svg width="60%" height="100%" >
                
            {isHome?arrowTopLeft(4*rm,4*rm,2*rm):arrowBottomLeft(4*rm,4*rm,2*rm)}
            {isHome?arrowTopRight(4*rm,4*rm,2*rm):arrowBottomRight(4*rm,4*rm,2*rm)}
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="8vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{
                `${val.count}`
            }</text>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="6vw" 
            x={0.5*rm} y={isHome?4.1*rm:2.5*rm}>{
            val.left
            }</text>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="6vw" 
            x={7.5*rm} y={isHome?4.1*rm:2.5*rm}>{
            val.right
            }</text>
            <text textAnchor="right" alignmentBaseline="middle" 
            fontSize="6vw" 
            x={10*rm} y={isHome?3.1*rm:1.5*rm}>{
            `Dives ${val.dive}`
            }</text>
            <text textAnchor="right" alignmentBaseline="middle" 
            fontSize="6vw" 
            x={10*rm} y={isHome?5.1*rm:3.5*rm}>{
            `Doubled ${val.double}`
            }</text>
            </svg>)
    }
    const onTouchLeftRight = async (e,f)=>{

    }
    const  bounded=(x)=>{
        return 0
    } 

    const onTouchPlayer = async e=>{
        console.log("onTouchPlayer",e)
    }
    const onTouchEnd = async (leftRight,updown,children)=>{

        setSelector(null)

    }

    console.log("selectedBunkers",selectedBunkers)

useEffect(()=>{
    let nbHome = 0
    let nbAway = 0
    let nbNeutral=0
    let nbAttack=0
    let nbBurnClock=0
    let nbAllAss=0
    let nbCounterAllAss=0
    const plays = new Map()

    if (team && teams.size!==0) {
        teams.get(team).forEach(t=>{
            if (t.isHome)nbHome++
            else nbAway++
            switch(t.playGoal){
                case "NEUTRAL":
                    nbNeutral+=1
                    break
            case "ATTACK":
                    nbAttack+=1
                    break
            case "BURN_CLOCK":
                    nbBurnClock+=1
                    break
            case "ALL_ASS":
                    nbAllAss+=1
                    break
            case "COUNTER_ALL_ASS":
                    nbCounterAllAss+=1
                    break
            default: 
                nbNeutral+=1                         
            }
            
            const breakoutSignature = []
            let i=0
            t.breakout.forEach(br=>{
                breakoutSignature.push(br.bunkerIndex)
                if (br.double)breakoutSignature.push(br.bunkerIndex)
            })
            const playSignature = breakoutSignature.sort().join(",")
            t.playSignature=playSignature
            if (plays.has(playSignature)){
                plays.get(playSignature).count+=1
            } else{
                plays.set(playSignature,{name:`Play ${plays.size+1}`, count:1})
            }
           

        })
    }
    setPlaysMap(plays)
    setNb({
        nbHome:nbHome,
        nbAway:nbAway,
        nbNeutral:nbNeutral,
        nbAttack:nbAttack,
        nbBurnClock:nbBurnClock,
        nbAllAss:nbAllAss,
        nbCounterAllAss:nbCounterAllAss
    })
},[team,teams])

    const totalPointForFilter = ()=>{
        switch(analyzeBy){
            case "All Points":
                return nb.nbHome + nb.nbAway
            case "Home":
                return nb.nbHome 
            case "Away":
                return nb.nbAway
            case "NEUTRAL":
                return nb.nbNeutral
            case "ATTACK":
                return nb.nbAttack
            case "BURN_CLOCK":
                return nb.nbBurnClock
            case "ALL_ASS":
                return nb.nbAllAss
            case "COUNTER_ALL_ASS":
                return nb.nbCounterAllAss
            default:
                return playsMap.get(analyzeBy).count                                         
        }


    }

    const playedBunkers =[]
    const pointList = []
    console.log("DEBUG", team, teams)
    if (team && teams.size!==0) {
        teams.get(team).forEach(t=>{
            switch(analyzeBy){
                case "All Points":
                    break
                case "Home":
                    if (t.isHome!==true)return
                    break
                case "Away":
                    if (t.isHome===true)return
                    break
                case "NEUTRAL":
                    if (t.playGoal!=="NEUTRAL")return
                    break
                case "ATTACK":
                    if (t.playGoal!=="ATTACK")return
                    break
                case "BURN_CLOCK":
                    if (t.playGoal!=="BURN_CLOCK")return
                    break
                case "ALL_ASS":
                    if (t.playGoal!=="ALL_ASS")return
                    break
                case "COUNTER_ALL_ASS":
                    if (t.playGoal!=="COUNTER_ALL_ASS")return
                    break
                default:
                    if (t.playSignature!==analyzeBy)return
                    break                                          

            }
            pointList.push(t)
            t.breakout.forEach(b=>{
            if (playedBunkers[b.bunkerIndex]){
                playedBunkers[b.bunkerIndex] = {
                    index: b.bunkerIndex,
                    count: playedBunkers[b.bunkerIndex].count+1,
                    left: b.direction==="LEFT"||(b.double&&b.direction==="UNKNOWN")?playedBunkers[b.bunkerIndex].left+1:playedBunkers[b.bunkerIndex].left,
                    right: b.direction==="RIGHT"||(b.double&&b.direction==="UNKNOWN")?playedBunkers[b.bunkerIndex].right+1:playedBunkers[b.bunkerIndex].right,
                    unknown: b.direction==="UNKNOWN"?playedBunkers[b.bunkerIndex].unknown+1:playedBunkers[b.bunkerIndex].unknown,
                    double:b.double?playedBunkers[b.bunkerIndex].double+1:playedBunkers[b.bunkerIndex].double,
                    dive:b.dive?playedBunkers[b.bunkerIndex].dive+1:playedBunkers[b.bunkerIndex].dive
                }
            }else
            playedBunkers[b.bunkerIndex] = {
                index: b.bunkerIndex,
                count: 1,
                left: b.direction==="LEFT"||(b.double&&b.direction==="UNKNOWN")?1:0,
                right: b.direction==="RIGHT"||(b.double&&b.direction==="UNKNOWN")?1:0,
                unknown: b.direction==="UNKNOWN"?1:0,
                double:b.double?1:0,
                dive:b.dive?1:0
            }
        })})
        
    }

    const teamLength = team && teams.size!==0?teams.get(team).length:0

    console.log("playedBunkers",playedBunkers)
    console.log("nb",nb,playsMap)
    return (
        <>

<div>
        <FormControl sx={{ m: 1, minWidth: 120 , width:"95%" }}>
                <InputLabel id="teamSelect">Team</InputLabel>
                <Select
                    labelId="teamSelect-label"
                    id="teamSelect-select-helper"
                    value={team}
                    label="Select Team"
                    onChange={handleChangeTeam}
                    >
                        {Array.from(teams.keys()).map((g,k)=>
                            <MenuItem value={g} key={k}>{g}</MenuItem>
                        )}
        </Select>
        <FormHelperText>Select the team to analyze</FormHelperText>
      </FormControl>  

      <FormControl sx={{ m: 1, minWidth: 120 , width:"95%" }}>
                <InputLabel id="filter">Analyze By</InputLabel>
                <Select
                    labelId="analyseby-label"
                    id="analyseby-helper"
                    value={analyzeBy}
                    label="Select Team"
                    onChange={e=>setAnalyzeBy(e.target.value)}
                    >
                        <MenuItem value={"All Points"}>All Points ({nb.nbHome+nb.nbAway}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <Divider/>
                        <MenuItem disabled value={"by Home/Away"}>By Home/Away</MenuItem>
                        <MenuItem  value={"Home"}>Home ({nb.nbHome}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <MenuItem  value={"Away"}>Away ({nb.nbAway}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <Divider/>
                        <MenuItem disabled value={"by Play Goal"}>By Play Goal</MenuItem>
                        <MenuItem value={"NEUTRAL"}>Nothing special ({nb.nbNeutral}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <MenuItem value={"ATTACK"}>Offensive play ({nb.nbAttack}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <MenuItem value={"BURN_CLOCK"}>Burn Clock ({nb.nbBurnClock}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <MenuItem value={"ALL_ASS"}>Run Down ({nb.nbAllAss}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <MenuItem value={"COUNTER_ALL_ASS"}>Counter Run Down ({nb.nbCounterAllAss}/{nb.nbHome+nb.nbAway})</MenuItem>
                        <Divider/>
                        <MenuItem disabled value={"by Play on Playbook"}>By Play on Playbook</MenuItem>
                        {
                            Array.from(playsMap.entries()).map((e,k)=><MenuItem key={k} value={e[0]}>{e[1].name} ({e[1].count}/{nb.nbHome+nb.nbAway})</MenuItem>

                            )
                        }
        </Select>
        <FormHelperText>Select by what dimension to analyze</FormHelperText>
      </FormControl> 
      </div>
        <div style={{textAlign:"center", width:"100%", height:`${7.6*rm}px`}}>
        {selector}
        </div>
        <TouchEvent onSwipe={onSwipe}>
        <div ref={imageRef} style={{touchAction:"none", position:"relative" ,overflow: "hidden", width:"100%", height:6*window.screen.width/8}}>
        
        <svg width="100%" height="100%" style={{position: "absolute",top:0,left:0, zIndex:10}}>


                {playedBunkers.filter(f=>f).map(b=>
                    {
                        return <><circle stroke="#000" fill="transparent"
                            strokeWidth="1.5" cx={dataHome[b.index][1]*px} cy={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px} r={1.5*rm}/>
                            <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.count}/{totalPointForFilter()}</text>
                            </>
                    
                })}
                {dataHome.map((v,i)=>
                    <TouchEvent k={i} onTouchBegin={onTouchBegin} 
                    onTouchLeftRight={onTouchLeftRight}
                    onTouchPlayer={onTouchPlayer}
                    onTouchEnd={onTouchEnd}>
                <circle key={i} id={`Home${v[0]}`} stroke="#aaa" fill="transparent"
                strokeWidth="0" strokeDasharray="5,5" onClick={onClick} cx={v[1]*px} cy={isHome?v[2]*px:(90-v[2])*px} r={1.5*rm}/>
                </TouchEvent>)}

        </svg>
        <img src="nxlwc.jpg" width={window.screen.width} style={{marginTop:isHome?-4*window.screen.width/8:0 , opacity:.3, transitionProperty: "margin-top", transitionDuration:"300ms", transitionDelay:"50ms"}} />
        </div>
        </TouchEvent>
        <div style={{width: "100%"}}>
          <Box sx={{p:2,display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"row"}}>
          <Button variant="contained" 
          onClick={()=>navigate("/")}
          >
            Home
          </Button>

          <Button variant="contained" 
          disabled = {pointList.length===0}
          onClick={()=>{
            window.localStorage.setItem("pointList",JSON.stringify({team:team, analyzeBy: analyzeBy, points:pointList}))
            navigate("/editpoints")}}
          >
            View/Edit Points
          </Button>
          
          </Box>
          </div>
        
        </>
    )
}
  export default Analytics;
