/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:a8085c6d-0398-4d50-b2cb-8cb1c3a17b47",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_0C6ig2jNt",
    "aws_user_pools_web_client_id": "36puii90oimh5ae305qftcehf5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://yqw6p4ldfjbxjc47ft2p2yolqi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "scoutingpb2005698868c44133b48bae30024f4cc855740-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
