import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Root from "./routes/Root2.js";
import Navigation from "./routes/Navigation.js"
import NewLayout from "./routes/NewLayout.js"
import PointSelect from "./routes/PointSelect.js"
import Scout from "./routes/Scout.js"
import AccountInfo from "./routes/AccountInfo.js"
import Analytics from "./routes/Analytics.js"
import EditPoints from "./routes/EditPoints.js"
import logo from "./logo.svg"
import { AmplifySignOut ,AmplifyAuthenticator} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";



function App() {
 /* const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      console.log("debug", nextAuthState, authData);
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);*/

  return (
<BrowserRouter>

    <Routes>
    
      <Route path="/" element={<Root />} />
      <Route path="navigation" element={<Navigation />} />
      <Route path="newlayout" element={<NewLayout />} />
      <Route path="pointselect" element={<PointSelect />} />
      <Route path="scout" element={<Scout />} />
      <Route path="accountinfo" element={<AccountInfo />} />
      <Route path="analytics" element={<Analytics />} />
      <Route path="editpoints" element={<EditPoints />} />
      <Route path="*" element={
        <main style={{ padding: "1rem" }}>
          <p>There's nothing here!</p>
        </main>} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
