//const React = require("react");
//const PropTypes = React.PropTypes;
import React from "react"
import PropTypes from "prop-types"
import createReactClass from "create-react-class"

function touchX (event) {
    return event.touches [0].clientX;
}

function touchY (event) {
    return event.touches [0].clientY;
}



const TouchEvent = createReactClass({

    propTypes: {
        children: PropTypes.node,
        tapTolerance: PropTypes.number,
        swipeTolerance: PropTypes.number,
        swipeBounds: PropTypes.number,
        onTap: PropTypes.func,
        onSwipe: PropTypes.func,
        onTouchBegin: PropTypes.func,
        onTouchLeftRight: PropTypes.func,
        onTouchPlayer: PropTypes.func,
        onTouchEnd: PropTypes.func,
        disableClick: PropTypes.bool,
    },

    getDefaultProps: function () {
        return {
            tapTolerance: 10,
            swipeTolerance: 30,
            swipeBounds: 30,
            disableClick: false,
        };
    },

    handleClick: function () {
        if (!this.usingTouch && this.props.onTap) {
            this.props.onTap();
        }
    },

    handleTouchStart: function (event) {
        event.preventDefault()
        event.stopPropagation()
        this.usingTouch = true;

        if (this.touchStarted) {
            return;
        }

        this.touchStarted = true;

        this.touchMoved = false;
        this.swipeOutBounded = false;
        this.leftRight = 0;
        this.upDown = 2 
        this.timestamp = new Date().getTime()

        this.startX = touchX(event);
        this.startY = touchY(event);

        this.currentX = 0;
        this.currentY = 0;
        if (this.props.onTouchBegin) {
            this.props.onTouchBegin(this.props.children);
        }
    },

    handleTouchMove: function (event) {
        event.preventDefault()
        event.stopPropagation()
        this.currentX = touchX(event);
        this.currentY = touchY(event);

        if (! this.touchMoved) {
            const tapTolerance = this.props.tapTolerance;

            this.touchMoved = Math.abs(this.startX - this.currentX) > tapTolerance ||
                Math.abs(this.startY - this.currentY) > tapTolerance;

        } else if (! this.swipeOutBounded) {
            const swipeOutBounded = this.props.swipeTolerance;

            this.swipeOutBounded = Math.abs(this.startX - this.currentX) > swipeOutBounded &&
                Math.abs(this.startY - this.currentY) > swipeOutBounded;

        }
        const deltaX = Math.sign(Math.round((this.currentX - this.startX)/this.props.swipeBounds))
        let deltaY = Math.round(10*(this.currentY - this.startY)/this.props.swipeBounds)
        if (Math.abs(deltaY)>5) deltaY = 5 * Math.sign(deltaY) 

        if (this.leftRight !== deltaX){
            if (this.props.onTouchLeftRight) {
                this.props.onTouchLeftRight(deltaX,deltaY,this.props.children);
            }
            this.leftRight = deltaX
            this.upDown = deltaY
        }

       
        if (this.upDown !== deltaY){
            if (this.props.onTouchLeftRight) {
                this.props.onTouchLeftRight(deltaX,deltaY,this.props.children);
            }
            this.leftRight = deltaX
            this.upDown = deltaY
        }
    },

    handleTouchCancel: function () {
        this.touchStarted = this.touchMoved = false;
        this.startX = this.startY = 0;
    },

    handleTouchEnd: function (event) {
        event.preventDefault()
        event.stopPropagation()
        this.touchStarted = false;
        if (! this.touchMoved ) {
            if (this.props.onTap) {
                this.props.onTap(event);
            }

        } else if (! this.swipeOutBounded) {
            if (this.props.onSwipe) {
                let swipeOutBounded = this.props.swipeTolerance,
                    direction;
                if (new Date().getTime()-this.timestamp <150){
                    if (Math.abs(this.startX - this.currentX) < swipeOutBounded) {
                        direction = this.startY > this.currentY ? "top" : "bottom";

                    } else {
                        direction = this.startX > this.currentX ? "left" : "right";
                    }
                this.props.onSwipe(direction, event);
                
                }
            }
        }
        if (this.props.onTouchEnd)
            if(new Date().getTime()-this.timestamp >150){
            this.props.onTouchEnd(this.leftRight,this.upDown,this.props.children)
                }else 
            this.props.onTouchEnd(2,0,this.props.children)
    },

    componentWillMount: function () {
        this.usingTouch = false;
    },


    render: function () {
        const children = this.props.children;
        const disableClick = this.props.disableClick;
        const element = children ? React.Children.only(children) : React.createElement("button", null);

        const eventBinding = {
            onClick: this.handleClick,
            onTouchStart: this.handleTouchStart,
            onTouchMove: this.handleTouchMove,
            onTouchCancel: this.handleTouchCancel,
            onTouchEnd: this.handleTouchEnd
        };

        if (disableClick)
            delete eventBinding.onClick;

        return React.cloneElement(element, eventBinding);
    }

});


//module.exports = TouchEvent;
export default TouchEvent;