import {
    Card, CardContent, CardActions, Button, TextField, Box
  } from "@mui/material";
import {useNavigate,useLocation,Link} from "react-router-dom"
import {useState, useEffect} from "react"
import Auth from '@aws-amplify/auth'
import {API} from "aws-amplify"
import {createAccount,updateAccount} from "../graphql/mutations"
import {getAccount} from "../graphql/queries"

const AccountInfo = ()=>{
  const location = useLocation()
  let navigate = useNavigate()
  const [id,setId] = useState()
  const [teamName, setTeamName] = useState('')
  const [division, setDivision] = useState('')
  const [accountExist, setAccountExist] = useState(false)

  useEffect(()=>{
    Auth.currentAuthenticatedUser().then((user)=>{
        setId(user.username)     
    })

},[])
useEffect(()=>{
    async function fetchAccount(){
    if (id){
        try{
            const res = await API.graphql({
            query: getAccount,
            variables:{
                id: id
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        })
        console.log("fectched", res)
        if (res.data.getAccount){
            setTeamName(res.data.getAccount.teamName)
            setDivision(res.data.getAccount.division)
            setAccountExist(true)
        }

        }catch(e){
            alert("failed to fetch account from  db", e.message)
        }
    }
    }   
    fetchAccount()
},[id])

const onSubmit = async ()=>{
    let account = {
        id: id,
        teamName: teamName,
        division: division    
    }
    try{
        const res = await API.graphql({
        query: accountExist?updateAccount:createAccount,
        variables:{
            input: account
        },
        authMode: "AMAZON_COGNITO_USER_POOLS"
    })
    }catch(e){
        alert("failed to store account in db", e.message)
    }

    navigate("/")
}



 
    return (
        <Card>
        <CardContent>
        <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '90%' },
      }}
      noValidate
      autoComplete="off">
        <div>
        <TextField
          required
          id="team-name"
          label="Team Name"
          value={teamName}
          onChange={e=>setTeamName(e.target.value)}
        />

        <TextField
          
          id="Division"
          label="Division"
          value={division}
          onChange={e=>setDivision(e.target.value)}
        />
        </div>
      
      </Box>


        </CardContent>
        <CardActions >
        <div style={{width: "100%"}}>
          <Box sx={{p:2,display:"flex",alignItems:"center",flexDirection:"column"}}>
            

          <Button variant="contained" 
          onClick={onSubmit}
          >
            Submit
          </Button>
          
          </Box>
          </div>
        </CardActions>
        </Card>
    )
}
  export default AccountInfo;