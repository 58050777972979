import {useState, useEffect, useRef, useCallback} from "react"
import {
    Card,CardContent, CardActions,Grid, Button,Typography,Box
  } from "@mui/material";

import Webcam from "react-webcam";
import ReactPlayer from "react-player/lazy";
import {useNavigate} from "react-router-dom"
import {makeStyles} from "@mui/styles";
import Record from "@mui/icons-material/AdjustOutlined";  
import Recording from "@mui/icons-material/FiberManualRecordTwoTone";

import TouchEvent from "../TouchEvent.js"
import { useLocation } from "react-router-dom"
import {API,Storage} from "aws-amplify"
import {createPoint} from "../graphql/mutations"


const useStyles = makeStyles(
    {
        root:{
            width: "100vw",
            height: "100vh"
        },
        webcam:{
            maxHeight: "100vh",
            height: "100%"
        },
        record:{
            transform: "Translate(-50%,-50%)",
            fontSize: "80px"
        },
        recording:{
            transform: "Translate(-50%,-50%)",
            fontSize: "80px",
            animation: `$recordBlink 2000ms infinite`,
        },
        "@keyframes recordBlink": {
            "0%": {opacity: 1},
            "50%": {opacity: .1},
            "100%": {opacity: 1}
          },
        debug:{
            position: "absolute" ,
            width: "10%",
              top: "50%" ,
              left: "80%"
        },
        playback:{
                width: "100%"
        },
        rotate90:{
            transform: "rotate(90deg)"
        },
        rotate270:{
            transform: "rotate(270deg)"
        },
        rotate180:{
            transform: "rotate(180deg)"
        },
        home:{

        },
        away:{

        }
    }
)

//const arrowTopLeft = <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g transform="rotate(-90 8 8)"><path fill="currentColor" fill-rule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g></svg>
const a = 5
const b = 30/14.833333333
const c = 18/14.833333333
const arrowTopLeft =(x,y,r,z)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(0,-4.1) rotate(-90 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
    }
const arrowTopRight = (x,y,r,z)=>{
return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1) rotate(0 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
}

const arrowBottomLeft =(x,y,r,z)=>{
    return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(0,-4.1) rotate(180 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
    }
const arrowBottomRight = (x,y,r,z)=>{
return <g transform={`translate(${x-b*r},${y-c*r}) scale(${r/a}) translate(4.1,-4.1) rotate(90 8 8)`}><path fill={z?z:"currentColor"} fillRule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8a4 4 0 0 0 0-8z"/></g>
}



/*const dataHome=[["5",20,72.5],["4",37.5,62.5],["3",50,72.5],["2",72,78.5],
                ["1",88,69],["texas",60,58],["houston",72.5,32.5],
                ["money",52,33], ["cobra",30,51.5],["s1",18,34],
                ["s2",18,20],["p1",29,28],["d1",102,60],["d2",82,54],
                ["d3",102.5,43.5],["d4",86.5,40.5],["sanantonio",65,15],
                ["d50",106,28],["dallas",91,15],["mw",103,15],["p2",40,20]]

htps const dataHome=[["5",102.5,78.5],["4",80,78.5],["3",60,71],["2",42.5,78.5],
                ["1",10,77.5],["texas",50,51],["zeus",60,41],["houston",72.5,51],
                ["money",90,15], ["cobra",111.5,60.5],["s1",86,61],
                ["s2",98,48.5],["s3",99,31.5],["s50",105,15],
                ["spur",70,15],["pill",60,15],["pill2",40,15],["d1",27.5,70],["d2",17.5,60],
                ["d3",10,45],["d4",10,25],["sanantonio",82,15],
                ["dallas",27.5,15],["god",50,15],["c",111,40],["delta",30,40]]

usxbl const dataHome=[["5",103,78.5],["4",78.5,78.5],["3",58.5,72],["2",40,78.5],
["1",12.5,74],["texas",70,57],["zeus",80,41],["houston",52,52],
["money",43,15], ["cobra",53,30],["s1",90,61],
["s2",101,50.5],["s3",102.5,39],["s50",102.5,15],
["spur",122.5,28.5],["delta",29.5,64],["dallas",36,30.5],["d1",19,50],["d2",17.5,37.5],
["d3",13,23],["snaked",112,27.5],
["god",60,15]]

nxl const dataHome=[["5",102.5,72.5],["4",80,72],["3",60,78.5],["2",40,78.5],
["1",23.5,66.5],["texas",68.5,58.5],["zeus",58.5,40],["houston",40,52.5],
["money",84,30], ["cobra",90,58],["s1",101.5,46.5],
["s2",108,39],["s3",103,27,5],["s50",108,15],
["spur",28.5,30],["dallas",44,30],["d1",18.5,52],["d2",17.5,37.5],
["d3",13.5,23],["wing",53,15],["god2",30,15],
["god",70,15]]
nxl2 dataHome=[["5",100,78.5],["4",77.5,70],["3",60,78.5],["2",42.5,68.5],
["1",17.5,72.5],["texas",58.5,48.5],["zeus",46,28.5],["houston",70,28.5],
["money",81.5,30], ["cobra",27.5,58.5],["s1",20,42],
["s2",17.5,26],["s50",17.5,15],
["spur",31.5,15],["dallas",85,15],["d1",92.5,60],["d2",82,50],
["d3",97,40],["d4",97,22.5],["wing",107.5,31],
["god",107.5,15]]
nxl3 const dataHome=[["5",90,68.5],["4",77.5,78.5],["3",60,72.5],["2",42.5,78.5],
["1",28,68.5],["texas",48,52.5],["zeus",67.5,22],["houston",72.5,52.5],
["money",11.5,72.5], ["cobra",12.5,32],["s1",18.5,42],
["s50",13,15],["p1",30,40],["p2",30,22],["seatle",40,15],
["spur",54,32.5],["spur2",74,32.5],["dallas",85,15],["d1",108,62],["d2",97,51],
["d3",103,40],["d4",107.5,27.5],["d50",107,15]]*/
const dataHome=[["5",13.5,78],["4",40,72],["3",60,77.5],["2",80,72],
["1",102,78],["texas",60,42],["zeus",52,15],["god",60,15],["zeus2",68,15],["houston",44,32],
["delta",70,59], ["cobra",22,62],["s1",16,47],["s2",12,36],["s3",12,22],
["p1",40,15],["p2",30,15],["p3",83,15],["dallas",100,15],
["spur",78,32.5],["d1",102.5,57],["d2",88.5,52],
["d3",98.5,40],["d4",102.5,27.5]]

const Scout = (props)=>{
    const location = useLocation()
    console.log("location",location, props)
    const classes = useStyles()
    const [orientation, setOrientation] = useState(window.innerWidth < window.innerHeight?"portrait":"landscape")
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [videoFilePath, setVideoFilePath] = useState(null);
    const [selector,setSelector] = useState(null) 
    const [selectedBunkers, setSelectedBunkers] = useState([])
    const [selectedBunker, setSelectedBunker] = useState(null)
    const [isHome,setIsHome]=useState(true)
    
    const [id,setId] = useState("0")
    const [team, setTeam] = useState('')
    const [index, setIndex] = useState(0)
    const [players, setPlayers] = useState([])
    const [deltaScore, setDeltaScore] = useState(0)
    const [submiting,setSubmiting] = useState(false)
    const [playGoal,setPlayGoal] = useState("NEUTRAL")
    let navigate = useNavigate()
    const imageRef= useRef()

    useEffect(()=>{
        if (imageRef && imageRef.current && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)){
            imageRef.current.addEventListener('touchstart',preventDefault,{passive:false})
            imageRef.current.addEventListener('touchmove',preventDefault,{passive:false})
            imageRef.current.addEventListener('touchend',preventDefault,{passive:false})

        }return function cleanup(){
            if (imageRef && imageRef.current && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)){
                imageRef.current.removeEventListener('touchstart',preventDefault,{passive:false})
                imageRef.current.removeEventListener('touchmove',preventDefault,{passive:false})
                imageRef.current.removeEventListener('touchend',preventDefault,{passive:false})
            }
              
        }

    },[imageRef,orientation])
    
    const preventDefault = e=>{
        e.preventDefault()}

    useEffect(()=>{

        const objStr =  window.localStorage.getItem("save")
        if (objStr){
          const obj = JSON.parse(objStr)
          setId(obj.id)
          setTeam(obj.team)
          setIndex(obj.index)
          setPlayGoal(obj.playGoal)
          setPlayers(()=>{const res= obj.players?obj.players.join(",").sort():[]
            const res2= [...res]
                res2.push("+2")
                res2.push("D")
                res2.push(" ")
                res2.push(" ")
                res2.push("X")
            //res2.splice(Math.floor(res2.length/2),0,"-")               
            return res2})
          setDeltaScore(obj.deltaScore)
        }
    },[])
    
    useEffect(()=>{
        const onOrientationChange = (event)=>{
           setOrientation(event.target.screen.orientation.angle===0 || event.target.screen.orientation.angle===180?"portrait":"landscape")
           if (capturing)handleStopCaptureClick()
        }


        const onContextMenu = (event)=>{
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        const onResize = (event)=>{
                setTimeout(()=>{
                setOrientation(window.innerWidth < window.innerHeight?"portrait":"landscape")
                if (capturing && window.innerWidth < window.innerHeight)handleStopCaptureClick()
                                },200)
        }
        


        if (window){
            window.addEventListener("orientationchange",onOrientationChange)
            window.addEventListener("contextmenu",onContextMenu)
            window.addEventListener("resize",onResize)
        }
            
        return function cleanup(){
            if (window){
                window.removeEventListener("orientationchange",onOrientationChange)
                window.removeEventListener("contextmenu",onContextMenu)
                window.removeEventListener("resize",onResize)
            }
        }
    },[])
    
    const handleDataAvailable = useCallback(
        ({ data }) => {
          if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
    
            const video = new Blob([data], {
              type: "video/mp4",
            });
            const url = window.URL.createObjectURL(video);
            setVideoFilePath(url);
            console.log(url);
          }
        },
        [setRecordedChunks, setVideoFilePath]
      );
    
      const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        console.log(webcamRef);
        console.log(mediaRecorderRef);
        var options = { mimeType: "video/mp4" };
        var iphoneOptions = {
            mimeType: 'video/mp4;' ,
            audioBitsPerSecond:8000,
            videoBitsPerSecond:1000000
        }
    
        if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent))
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, iphoneOptions);
        else
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            options,
            });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.start();
      }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);
    
      const handleStopCaptureClick = useCallback(() => {
        setCapturing(false);
        mediaRecorderRef.current.stop();
      }, [mediaRecorderRef, setCapturing]);
    
    
    const onClick = (e)=>{
        e.preventDefault()
        console.log("event",e.clientX,e.clientY)
    }

    const onClickLeft = (e)=>{
        
        if (selectedB){
            if (selectedB.leftRight === -1) selectedB.leftRight = 0
            else selectedB.leftRight = -1
        }
        updateSelector()
        
    }
    const onClickRight = (e)=>{
        if (selectedB){
            if (selectedB.leftRight === 1) selectedB.leftRight = 0
            else selectedB.leftRight = 1
        }
        updateSelector()
        
    }
    const onClickPlus2 = (e)=>{
        if (selectedB){
            if (selectedB.player === "+2") selectedB.player = " "
            else selectedB.player = "+2"
        }
        updateSelector()
        
    }
    const onClickDive = (e)=>{
        if (selectedB){
            if (selectedB.player === "D") selectedB.player = " "
            else selectedB.player = "D"
        }
        updateSelector()
        
    }

    const updateSelector = ()=>{
    if (!selectedB)return
    if (selectedB.leftRight === -1)
    setSelector(<svg width="30%" height="100%" >{isHome?arrowTopLeft(4*rm,4*rm,2*rm):arrowBottomLeft(4*rm,4*rm,2*rm)}
    <text textAnchor="middle" alignmentBaseline="middle" 
        fontSize="9vw" 
        x={4*rm} y={isHome?4.1*rm:2.5*rm}>{selectedB.player}</text></svg>)
    else if (selectedB.leftRight===1)
    setSelector(<svg width="30%" height="100%" >{isHome?arrowTopRight(4*rm,4*rm,2*rm):arrowBottomRight(4*rm,4*rm,2*rm)}
    <text textAnchor="middle" alignmentBaseline="middle" 
        fontSize="9vw" 
        x={4*rm} y={isHome?4.1*rm:2.5*rm}>{selectedB.player}</text></svg>)
    else setSelector(
        <svg width="30%" height="100%" ><circle stroke="#000" fill="transparent"
        strokeWidth="4.2" cx={4*rm} cy={isHome?4*rm-1:2.5*rm-1} r={2*rm-2}/>
        <text textAnchor="middle" alignmentBaseline="middle" 
        fontSize="9vw" 
        x={4*rm} y={isHome?4.1*rm:2.5*rm}>{selectedB.player}</text>
        </svg>)
    }

    useEffect(()=>{
        const onSubmit2 = async ()=>{
            let point = {
                id: id,
                team: team,
                index: index,
                isHome: isHome,
                s3URL: recordedChunks.length>0?`${id}/${team}_${index}.mp4`:null,
                breakout:selectedBunkers.map(b=>{return {
                    direction: b.leftRight===0?"UNKNOWN":b.leftRight===-1?"LEFT":"RIGHT",
                    bunkerIndex: b.index,
                    Player: b.player!=='+2'&&b.player!=='D'?b.player:"",
                    double: b.player==='+2',
                    dive: b.player==='D'
                }
                }),
                timestamp: new Date().toISOString(),
                notes: null,
                pathway: null,
                deltaScore: deltaScore,
                playGoal: playGoal,
                owner:id.substring(0,36)
            
            }
            try{
                const res = await API.graphql({
                query: createPoint,
                variables:{
                    input: point
                },
                authMode: "AWS_IAM"
            })
            }catch(e){
                alert("failed to store in db" +JSON.stringify(point))
                alert("failed to store in db"+ JSON.stringify(e))
            }
            if (recordedChunks.length>0)
            try{
                const res = await Storage.put(
                    `${id}/${team}_${index}.mp4`,
                    new File(recordedChunks,`${id}/${team}_${index}`),
                    {contentType: "video/mp4"}
                )
            }catch(e){
                alert("failed to store in s3", e.message)
            }
            window.localStorage.setItem("save",JSON.stringify(
                {
                  id:id,
                  team:team,
                  index:parseInt(index)+1,
                  deltaScore: deltaScore,
                  playGoal: "NEUTRAL",
                  players: players.filter(p=>p!=='X' && p!=='D' && p!=='+2' && p!==" ").join(",")
                }
              ))
            navigate("/pointselect")
        }
        if (submiting) onSubmit2()
    },
    [submiting])
    const onSubmit = async ()=>{
        let point = {
            id: id,
            team: team,
            index: index,
            isHome: isHome,
            s3URL: `${id}/${team}_${index}.mp4`,
            breakout:selectedBunkers.map(b=>{return {
                direction: b.leftRight===0?"UNKNOWN":b.leftRight===-1?"LEFT":"RIGHT",
                bunkerIndex: b.index,
                Player: b.player}
            }),
            timestamp: new Date().toISOString(),
            notes: null,
            pathway: null,
            deltaScore: deltaScore,
            playGoal: playGoal,
            owner:id.substring(0,36)
        
        }
        try{
            const res = await API.graphql({
            query: createPoint,
            variables:{
                input: point
            },
            authMode: "AWS_IAM"
        })
        }catch(e){
            alert("failed to store in db", e.message)
        }
        if (recordedChunks.length>0)
        try{
            const res = await Storage.put(
                `${id}/${team}_${index}.mp4`,
                new File(recordedChunks,`${id}/${team}_${index}`),
                {contentType: "video/mp4"}
            )
        }catch(e){
            alert("failed to store in s3", e.message)
        }
        window.localStorage.setItem("save",JSON.stringify(
            {
              id:id,
              team:team,
              index:parseInt(index)+1,
              deltaScore: deltaScore,
              playGoal: "NEUTRAL",
              players: players.filter(p=>p!=='X' && p!==' ' && p!=="D" && p!=="+2").join(",")
            }
          ))
        navigate("/pointselect")
    }

    const px = window.screen.width/120
    const rm = window.screen.width/30

    const onTap = async e=>{
        console.log("onTap",e)
    }
    const onSwipe = async e=>{
        console.log("onSwipe",e)
        if (e === "bottom" && isHome)
            setIsHome(false)
        else if (e=== "top" && !isHome)
            setIsHome(true)
    }

    const onTouchBegin = async e=>{
        console.log("onTouchBegin",e)
        setSelector(
            <svg width="30%" height="100%" >
            <circle stroke="#000" fill="transparent"
            strokeWidth="4.2" cx={4*rm} cy={isHome?4*rm-1:2.5*rm-1} r={2*rm-2}/></svg>)
    }
    const onTouchLeftRight = async (e,f)=>{
        console.log("onTouchLeftRight",e,f)
        if (e===-1)
        setSelector(<svg width="30%" height="100%" >{isHome?arrowTopLeft(4*rm,4*rm,2*rm):arrowBottomLeft(4*rm,4*rm,2*rm)}
        <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="9vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{players[bounded(f+Math.floor(players.length/2)-1)]}</text></svg>)
        else if (e===1)
        setSelector(<svg width="30%" height="100%" >{isHome?arrowTopRight(4*rm,4*rm,2*rm):arrowBottomRight(4*rm,4*rm,2*rm)}
        <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="9vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{players[bounded(f+Math.floor(players.length/2)-1)]}</text></svg>)
        else setSelector(
            <svg width="30%" height="100%" ><circle stroke="#000" fill="transparent"
            strokeWidth="4.2" cx={4*rm} cy={isHome?4*rm-1:2.5*rm-1} r={2*rm-2}/>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="9vw" 
            x={4*rm} y={isHome?4.1*rm:2.5*rm}>{players[bounded(f+Math.floor(players.length/2)-1)]}</text>
            </svg>)
    }
    const  bounded=(x)=>{
        if (x<0)return 0
        else if (x>=players.length) return players.length-1
        else return x
    } 

    const onTouchPlayer = async e=>{
        console.log("onTouchPlayer",e)
    }
    const onTouchEnd = async (leftRight,updown,children)=>{
        console.log("onTouchEnd",children.key,leftRight,updown)
        if (leftRight!==2){
            const found = selectedBunkers.findIndex(p=>p.index===parseInt(children.key))
            if (found>=0)selectedBunkers.splice(found,1)
            if (players[bounded(updown+Math.floor(players.length/2)-1)]==="X"){
                
                setSelectedBunkers([...selectedBunkers])
                setSelector(null)
                setSelectedBunker(null)

            }else{
                if (selectedBunkersCount()<5){
                    setSelectedBunker(parseInt(children.key))
                    setSelectedBunkers([...selectedBunkers,{index:parseInt(children.key),leftRight:leftRight,player:players[bounded(updown+Math.floor(players.length/2)-1)]}])
                }else setSelector(null)
            }
        }else
            setSelector(null)
    }

    const selectedBunkersCount = ()=>{
        let res = 0
        selectedBunkers.forEach(b=>{
            if (b.player==="+2")res = res + 2 
            else res++
        })
        return res
    }

    console.log("selectedBunkers",selectedBunkers)

    let selectedB = selectedBunkers.find(f=>f.index === selectedBunker)

    return (
        orientation==="portrait"?
        <>
        {recordedChunks.length > 0 ? 
                                <ReactPlayer
                                  controls
                                  playsinline={true}
                                  muted={true}
                                  loop={true}
                                  playing={true}
                                  className={classes.playback}
                                  width="100%"
                                  height="100%"
                                  style={{maxHeight: "328px"}}
                                  url={videoFilePath}
                                />   :<div width="100%"
                                           height="100%" 
                                           style={{maxHeight: "328px"}}>
                                <Typography variant="body2" 
                                            textAlign="center" 
                                            color="#777"
                                            padding= "10px">
            Flip the phone in landscape mode to record the break out, then switch back to portrait mode to see the video and populate the breakout bunkers, where they are shooting (arrow), did they run and gun or dive (D), did they double up (+2).
            swipe up or down to set home or away view. 
                                </Typography>

                                </div> 
        }
        <div style={{marginLeft: "10px", textAlign:"center", width:"100%", height:`${7.6*rm}px`}}>
        {selector && <><svg onClick={onClickLeft} width="17.5%" height="100%" >{isHome?arrowTopLeft(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===-1?"#000":"#AAA"):arrowBottomLeft(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===-1?"#000":"#AAA")}
        </svg>
        <svg onClick={onClickRight} width="17.5%" height="100%" >{isHome?arrowTopRight(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===1?"#000":"#AAA"):arrowBottomRight(2*rm,3*rm,1*rm,selectedB && selectedB.leftRight===1?"#000":"#AAA")}
        </svg>
        {selector}
        <svg onClick={onClickPlus2} width="17.5%" height="100%" ><circle stroke={selectedB && selectedB.player==="+2"?"#000":"#AAA"} fill="transparent"
            strokeWidth="2.1" cx={2*rm} cy={3*rm} r={1*rm-1}/>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="4.5vw" fill={selectedB && selectedB.player==="+2"?"#000":"#AAA"}
            x={2*rm} y={3.06*rm}>+2</text>
            </svg>
        <svg onClick={onClickDive} width="17.5%" height="100%" ><circle stroke={selectedB && selectedB.player==="D"?"#000":"#AAA"} fill="transparent"
            strokeWidth="2.1" cx={2*rm} cy={3*rm} r={1*rm-1}/>
            <text textAnchor="middle" alignmentBaseline="middle" 
            fontSize="4.5vw" fill={selectedB && selectedB.player==="D"?"#000":"#AAA"}
            x={2*rm} y={3.06*rm}>D</text>
            </svg>
        </>
        }
        </div>
        <TouchEvent onSwipe={onSwipe}>
        <div ref={imageRef} style={{touchAction:"none", position:"relative" ,overflow: "hidden", width:"100%", height:6*window.screen.width/8}}>
        
        <svg width="100%" height="100%" style={{position: "absolute",top:0,left:0, zIndex:10}}>


                {selectedBunkers.map(b=>
                    {if(b.leftRight===0){
                        return <><circle stroke="#000" fill="transparent"
                            strokeWidth="2.4" cx={dataHome[b.index][1]*px} cy={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px} r={rm}/>
                            <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.player}</text>
                            </>
                    }else if (b.leftRight===-1){
                        return <>{isHome?arrowTopLeft(dataHome[b.index][1]*px,dataHome[b.index][2]*px,rm):
                            arrowBottomLeft(dataHome[b.index][1]*px,(93-dataHome[b.index][2])*px,rm)}
                        <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.player}</text></>
                    }else {
                        return <>{isHome?arrowTopRight(dataHome[b.index][1]*px,dataHome[b.index][2]*px,rm):
                            arrowBottomRight(dataHome[b.index][1]*px,(93-dataHome[b.index][2])*px,rm)}
                        <text textAnchor="middle" alignmentBaseline="middle" 
                            fontSize="4vw" fontWeight={900}
                            x={dataHome[b.index][1]*px} y={isHome?dataHome[b.index][2]*px:(90-dataHome[b.index][2])*px}>{b.player}</text></>
                    }
                })}
                {selectedBunker !==null &&
                    <circle stroke="#333" fill="transparent"
                            strokeWidth="1" strokeDasharray="5,5" cx={dataHome[selectedBunker][1]*px} cy={isHome?dataHome[selectedBunker][2]*px:(90-dataHome[selectedBunker][2])*px} r={1.5*rm}/>
                }
                {dataHome.map((v,i)=>
                    <TouchEvent k={i} onTouchBegin={onTouchBegin} 
                    onTouchLeftRight={onTouchLeftRight}
                    onTouchPlayer={onTouchPlayer}
                    onTouchEnd={onTouchEnd}>
                <circle key={i} id={`Home${v[0]}`} stroke="#aaa" fill="transparent"
                strokeWidth="0" strokeDasharray="5,5" onClick={onClick} cx={v[1]*px} cy={isHome?v[2]*px:(90-v[2])*px} r={1.5*rm}/>
                </TouchEvent>)}

        </svg>
        <img src="nxlwc.jpg" width={window.screen.width} style={{marginTop:isHome?-4*window.screen.width/8:0 , opacity:.5, transitionProperty: "margin-top", transitionDuration:"300ms", transitionDelay:"50ms"}} />
        </div>
        </TouchEvent>
        <div style={{width: "100%"}}>
        <Box sx={{p:2,display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"row"}}>
                <Button variant="contained" 
                onClick={()=>navigate("/pointselect")}
                >
                  Back
                </Button>
                <Button variant="contained" 
                disabled = {(selectedBunkersCount()<4 && !recordedChunks.length>0) || submiting }
                onClick={()=>setSubmiting(true)}
                >
                  Submit
                </Button>
                
                </Box>
        </div>
        
        </>:
        <>
            <div className={classes.webcam} style={{ width:"100%", height:"100%"}}>
                <Webcam
                    audio={true}
                    muted={true}
                    ref={webcamRef}
                    className={classes.webcam}
                    
                    height={Math.min(window.screen.availWidth,window.screen.availHeight)}
                    videoConstraints={{
                        facingMode: {exact:"environment"},
                        width: Math.max(window.screen.availWidth,window.screen.availHeight),
                        height: Math.min(window.screen.availWidth,window.screen.availHeight)
                    }}
                />
            </div>
            <div className={classes.debug}>
                {capturing?<Recording className={classes.recording} 
                       fontSize = "80px"
                       htmlColor = "rgba(255,0,0,.7)"
                       onClick={handleStopCaptureClick}
                        />:<Record className={classes.record} 
                       fontSize = "80px"
                       htmlColor = "rgba(255,255,255,.7)"
                       onClick={handleStartCaptureClick}
                />}
            </div>
        </>

    )
}
  export default Scout;
