/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      teamName
      division
      features
      tournaments {
        items {
          id
          accountId
          tournamentLeague
          tournamentDivision
          tournamentDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      teamName
      division
      features
      tournaments {
        items {
          id
          accountId
          tournamentLeague
          tournamentDivision
          tournamentDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      teamName
      division
      features
      tournaments {
        items {
          id
          accountId
          tournamentLeague
          tournamentDivision
          tournamentDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTournamentForAccount = /* GraphQL */ `
  mutation CreateTournamentForAccount(
    $input: CreateTournamentForAccountInput!
    $condition: ModelTournamentForAccountConditionInput
  ) {
    createTournamentForAccount(input: $input, condition: $condition) {
      id
      accountId
      tournamentLeague
      tournamentDivision
      tournamentDate
      points {
        items {
          id
          team
          index
          isHome
          s3URL
          timestamp
          notes
          pathway
          deltaScore
          playGoal
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tournament {
        league
        date
        layout
        layoutImg
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTournamentForAccount = /* GraphQL */ `
  mutation UpdateTournamentForAccount(
    $input: UpdateTournamentForAccountInput!
    $condition: ModelTournamentForAccountConditionInput
  ) {
    updateTournamentForAccount(input: $input, condition: $condition) {
      id
      accountId
      tournamentLeague
      tournamentDivision
      tournamentDate
      points {
        items {
          id
          team
          index
          isHome
          s3URL
          timestamp
          notes
          pathway
          deltaScore
          playGoal
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tournament {
        league
        date
        layout
        layoutImg
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTournamentForAccount = /* GraphQL */ `
  mutation DeleteTournamentForAccount(
    $input: DeleteTournamentForAccountInput!
    $condition: ModelTournamentForAccountConditionInput
  ) {
    deleteTournamentForAccount(input: $input, condition: $condition) {
      id
      accountId
      tournamentLeague
      tournamentDivision
      tournamentDate
      points {
        items {
          id
          team
          index
          isHome
          s3URL
          timestamp
          notes
          pathway
          deltaScore
          playGoal
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tournament {
        league
        date
        layout
        layoutImg
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTournament = /* GraphQL */ `
  mutation CreateTournament(
    $input: CreateTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    createTournament(input: $input, condition: $condition) {
      league
      date
      layout
      layoutImg
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTournament = /* GraphQL */ `
  mutation UpdateTournament(
    $input: UpdateTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    updateTournament(input: $input, condition: $condition) {
      league
      date
      layout
      layoutImg
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTournament = /* GraphQL */ `
  mutation DeleteTournament(
    $input: DeleteTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    deleteTournament(input: $input, condition: $condition) {
      league
      date
      layout
      layoutImg
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPoint = /* GraphQL */ `
  mutation CreatePoint(
    $input: CreatePointInput!
    $condition: ModelPointConditionInput
  ) {
    createPoint(input: $input, condition: $condition) {
      id
      team
      index
      isHome
      s3URL
      breakout {
        direction
        bunkerIndex
        dive
        double
        Player
      }
      timestamp
      notes
      pathway
      deltaScore
      playGoal
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePoint = /* GraphQL */ `
  mutation UpdatePoint(
    $input: UpdatePointInput!
    $condition: ModelPointConditionInput
  ) {
    updatePoint(input: $input, condition: $condition) {
      id
      team
      index
      isHome
      s3URL
      breakout {
        direction
        bunkerIndex
        dive
        double
        Player
      }
      timestamp
      notes
      pathway
      deltaScore
      playGoal
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePoint = /* GraphQL */ `
  mutation DeletePoint(
    $input: DeletePointInput!
    $condition: ModelPointConditionInput
  ) {
    deletePoint(input: $input, condition: $condition) {
      id
      team
      index
      isHome
      s3URL
      breakout {
        direction
        bunkerIndex
        dive
        double
        Player
      }
      timestamp
      notes
      pathway
      deltaScore
      playGoal
      owner
      createdAt
      updatedAt
    }
  }
`;
