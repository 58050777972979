import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify , Auth} from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from '@aws-amplify/ui-react';
Amplify.configure(awsExports);
Auth.currentCredentials()
  .then(d => console.log('data: ', d))
  .catch(e => console.log('error: ', e))

  const defaultOptions = {
    passive: false,
    capture: false
  };
  const supportedPassiveTypes = [
    
    'touchstart', 'touchmove', 'touchenter', 'touchend', 'touchleave'
    
  ];
  const getDefaultPassiveOption = (passive, eventName) => {
    if (passive !== undefined) return passive;
  
    return supportedPassiveTypes.indexOf(eventName) === -1 ? false : defaultOptions.passive;
  };
  
  const getWritableOptions = (options) => {
    const passiveDescriptor = Object.getOwnPropertyDescriptor(options, 'passive');
      
    return passiveDescriptor && passiveDescriptor.writable !== true && passiveDescriptor.set === undefined
      ? Object.assign({}, options)
      : options;
  };
  
  const overwriteAddEvent = (superMethod) => {
    EventTarget.prototype.addEventListener = function (type, listener, options) {
      const usesListenerOptions = typeof options === 'object' && options !== null;
      const useCapture          = usesListenerOptions ? options.capture : options;
  
      options         = usesListenerOptions ? getWritableOptions(options) : {};
      options.passive = getDefaultPassiveOption(options.passive, type);
      //options.capture = useCapture === undefined ? defaultOptions.capture : useCapture;
  
      superMethod.call(this, type, listener, options);
    };
  
    EventTarget.prototype.addEventListener._original = superMethod;
  };
  
  const supportsPassive = true//eventListenerOptionsSupported();
  
  if (supportsPassive) {
    const addEvent = EventTarget.prototype.addEventListener;
    overwriteAddEvent(addEvent);
  }



ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
    <App />
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
